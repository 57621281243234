export const relativeFileSize = (bytes = 0) => {
  let sizeInMB = bytes / (1024 * 1024);

  return sizeInMB.toFixed(2) + ' MB';
};

export const percentage = (downloaded, total) => {
  if (!downloaded || !total) {
    return 0;
  }

  return Math.floor((downloaded / total) * 100);
};

/**
 * @param {Number} time
 * @returns date of given timestemp. e.g. 23
 */
export const getDate = time => {
  const d = time ? new Date(time) : new Date();
  return d.getDate();
};

/**
 * @param {Number} time
 * @returns weekday in short of given timestemp. e.g. Mon, Tue etc..
 */
export const getShortWeekDay = time => {
  const d = time ? new Date(time) : new Date();
  return d.toLocaleString('en-US', { weekday: 'short' });
};

/**
 * @param {Number} time
 * @returns month in short of given timestemp. e.g. Jan, Feb etc..
 */
export const getShortMonth = time => {
  const d = time ? new Date(time) : new Date();
  return d.toLocaleString('en-US', { month: 'short' });
};

/**
 * @param {Number} time
 * @returns year of given timestemp. e.g. 2020, 2022
 */
export const getFullYear = time => {
  const d = time ? new Date(time) : new Date();
  return d.getFullYear();
};

/**
 * @param {Number} time
 * @returns time in format of hour and monute given timestemp. e.g. 12:26 PM
 */
export const getFormatedTime = time => {
  const d = time ? new Date(time) : new Date();

  return d.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

/**
 * @param {Number} seconds
 * @returns number of minutes of given seconds
 */
export const getDurationInMinute = seconds => {
  return Math.round(seconds / 60) + ' min';
};

/**
 *
 * @param {Number} seconds
 * @returns {Number} Number of remaining seconds after minutes.
 */
export const getDurationInSeconds = seconds => {
  return seconds % 60;
};

/**
 * @returns {Promise} import snackbar component
 */
export const getSnackbar = async () => {
  try {
    const snackbar = await import('@dreamworld/dw-snackbar/dw-snackbar.js');
    return snackbar;
  } catch (error) {
    console.error('Failed to import dw-snackbar.js', error);
  }
};
