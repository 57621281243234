import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { lazyReducerEnhancer } from '@dreamworld/pwa-helpers/lazy-reducer-enhancer.js';
import { persistEnhancer } from '@dreamworld/pwa-helpers/redux-persist-enhancer.js';
import * as app from './app';
import * as registration from './registration';
import * as networkManager from './network-manager';
import * as settings from './settings';
import * as videos from './videos';
import initVideoPlayerClient from '@smpv/video-player-client';


export const sagaMiddleware = createSagaMiddleware();

const persistOptions = [{ path: 'settings', sharedBetweenTabs: true }, { path: 'registration', sharedBetweenTabs: true }];

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: true,
    })
  : compose;

// Initializes the Redux store with a lazyReducerEnhancer (so that you can
// lazily add reducers after the store has been created) and redux-thunk (so
// that you can dispatch async actions). See the "Redux and state management"
// section of the wiki for more details:
// https://pwa-starter-kit.polymer-project.org/redux-and-state-management
export const store = createStore(
  state => state,
  devCompose(lazyReducerEnhancer(combineReducers), applyMiddleware(sagaMiddleware), persistEnhancer(persistOptions))
);

// Initially loaded reducers.
store.addReducers({
  settings: settings.reducers.default,
  networkManager: networkManager.reducers.default,
  registration: registration.reducers.default,
  app: app.reducers.default,
  downloadStatus: videos.reducers.downloadStatus,
  deletedVideos: videos.reducers.deletedVideos,
  playStatus: videos.reducers.playStatus,
  videoUnlock: videos.reducers.unlock.default
});

//export store as global variable, so debugging through DevTools Console is possible if required
window.store = store;
window.networkManager = networkManager;

(async function() {
  const config = app.selectors.config(store.getState());
  const { playerServerUrl, playerServerToken } = config;
  console.info("Going to initialize video player client with", playerServerUrl);
  await initVideoPlayerClient(playerServerUrl, playerServerToken);
  console.log(window.Rpi);
  console.info("Video player client initialized");

  if (!window.location.pathname.startsWith('/video-controller')) {
    sagaMiddleware.run(app.saga.default);
    sagaMiddleware.run(registration.saga.default);
    sagaMiddleware.run(videos.saga.default);
    sagaMiddleware.run(settings.saga.default);
    sagaMiddleware.run(networkManager.saga.default);
  } else {
    sagaMiddleware.run(app.saga.default);
    console.debug("Not running sagas, as we are in video-controller");
  }
})();


