export const VIDEO_DELETED = 'VIDEOS_VIDEO_DELETED';

export const UPDATE_DOWNLOAD_STATUS = 'VIDEOS_UPDATE_DOWNLOAD_STATUS';
export const DOWNLOAD_PROGRESS = 'VIDEOS_DOWNLOAD_PROGRESS';
export const VALIDATION_STARTED = 'VIDEOS_VALIDATION_STARTED';

// time validation failed
export const OPEN_PREVIEW = 'VIDEOS_OPEN_PREVIEW';
export const CLOSE_PREVIEW = 'VIDEOS_CLOSE_PREVIEW';

export const START_PREVIEW = 'VIDEOS_START_PREVIEW';
export const STOP_PREVIEW = 'VIDEOS_STOP_PREVIEW';

export const START_PLAY = 'VIDEOS_START_PLAY';
export const STOP_PLAY = 'VIDEOS_STOP_PLAY';

export const PLAY_STARTED = 'VIDEOS_PLAY_STARTED';
export const PLAY_PAUSED = 'VIDEOS_PLAY_PAUSED';
export const UPDATE_PLAYED_DURATION = 'VIDEOS_UPDATE_PLAYED_DURATION';
export const PLAY_COMPLETED = 'VIDEOS_PLAY_COMPLETED';
export const RESET_LOCAL_PLAY_STATUS = 'VIDEOS_RESET_LOCAL_PLAY_STATUS';
export * from './actions-unlock.js';

/**
 * Opens preview session
 * @param {*} param0
 *  @property {String} playStatusId Play status Id
 */
export const openPreview = playStatusId => {
  return {
    type: OPEN_PREVIEW,
    playStatusId,
  };
};

/**
 * Closes preview session
 * @param {*} param0
 *  @property {String} videoId Video Id.
 */
export const closePreview = videoId => {
  return {
    type: CLOSE_PREVIEW,
    videoId,
  };
};

/**
 * Loads preview key through `openPreview` & start preview video.
 * @param {String} playStatusId
 */
export const startPreview = playStatusId => {
  return {
    type: START_PREVIEW,
    playStatusId,
  };
};

export const stopPreview = videoId => {
  return {
    type: STOP_PREVIEW,
    videoId,
  };
};



/**
 * Loads key through `openPlay` & start actual video.
 * @param {String} playStatusId Play status Id
 */
export const startPlay = playStatusId => {
  return {
    type: START_PLAY,
    playStatusId,
  };
};

export const stopPlay = videoId => {
  return {
    type: STOP_PLAY,
    videoId,
  };
};

export const downloadProgress = (videoId, stats) => {
  return {
    type: DOWNLOAD_PROGRESS,
    videoId,
    stats,
  };
};

/**
 * Dispatched every time when it gets data from the server when video downloading is in progress.
 * Note: This action will be dispatched by the download manager.
 * @param {String} videoId Video Id.
 * @param {*} stats
 *  @property {Number} total total size that needs to be downloaded in bytes
 *  @property {Number} downloaded downloaded size in bytes
 *  @property {Number} progress progress porcentage 0-100%
 *  @property {Number} speed download speed in bytes
 */
export const updateDownloadStatus = (videoId, doc) => {
  return {
    type: UPDATE_DOWNLOAD_STATUS,
    videoId,
    doc,
  };
};

/**
 * Dispatched when file is downloaded & validation/extraction is started.
 * Note: This action will be dispatched by the download manager.
 * @param {String} videoId Video Id.
 */
export const validationStarted = videoId => {
  return {
    type: VALIDATION_STARTED,
    videoId,
  };
};

/**
 * Sets status to `PLAYING`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id
 */
export const playStarted = ({ videoId, playStatusId }) => {
  return {
    type: PLAY_STARTED,
    videoId,
    playStatusId,
  };
};

/**
 * Updates `playedDuration` of the currently playing video.
 * @param {Number} playedDuration Number of seconds video is played from the starting.
 */
export const updatePlayedDuration = ({ playedDuration }) => {
  return {
    type: UPDATE_PLAYED_DURATION,
    playedDuration,
  };
};

/**
 * Sets status to `PAUSED`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id
 */
export const playPaused = ({ videoId, playStatusId }) => {
  return {
    type: PLAY_PAUSED,
    videoId,
    playStatusId,
  };
};

/**
 * Sets status to `PLAYED`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id
 */
export const playCompleted = ({ videoId, playStatusId }) => {
  return {
    type: PLAY_COMPLETED,
    videoId,
    playStatusId,
  };
};

/**
 * Resets local play status.
 */
export const resetLocalPlayStatus = () => {
  return {
    type: RESET_LOCAL_PLAY_STATUS,
  };
};

/**
 * Note: This action will be dispatched by the download manager.
 * @param {String} videoId Video Id which will be deleted from the disk.
 */
export const videoDeleted = videoId => {
  return {
    type: VIDEO_DELETED,
    videoId,
  };
};
