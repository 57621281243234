import { takeEvery, call, select, all, delay, put, fork, take, cancel } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import * as unlockSelectors from './selectors-unlock.js';
import * as routerSelectors from '../router/selectors.js';
import * as appSelectors from '../app/selectors.js';
import * as registrationSelector from '../registration/selectors.js';
import { forEach, includes, isEmpty, isEqual, reject } from 'lodash-es';
import { navigateDialog, back } from '@dreamworld/router';
import { isLocationInAnyRange, isTimeInAllowedStaleRange } from './gps-util.js';
import { store } from '../store.js';
import api from '../api.js';
import dreamdb from '@dw/dreamdb-client';

let db;
window.navigateDialog = navigateDialog;
window.back = back;

const computeUnlockStatus = (state, vps) => {
  const currTime = Date.now();
  const { _id, time, locations, rfid, passwordMethod, maxPasscodeAttempts, usedPasscodeAttempts } = vps;
  const duration = selectors.doc(state, vps.videoId)?.duration * 1000;
  const { unlockBufferTime, playBufferTime } = appSelectors.config(state);
  const unlockWindowOpensAt = time - unlockBufferTime;

  if (maxPasscodeAttempts && usedPasscodeAttempts >= maxPasscodeAttempts) {
    return {
      status: 'LOCKED_OUT'
    };
  }

  if (currTime < unlockWindowOpensAt) {
    return {
      status: 'NOT_AVAILABLE',
      unlockWindowOpensAt,
    };
  }

  const playWindowClosedAt = time + duration + playBufferTime;
  if (currTime >= unlockWindowOpensAt && currTime <= playWindowClosedAt) {
    return {
      status: 'READY',
      gps: { status: isEmpty(locations) ? 'NOT_APPLICABLE' : 'PENDING' },
      rfid: { status: !rfid ? 'NOT_APPLICABLE' : 'PENDING' },
      passcode: { 
        status: !passwordMethod ? 'NOT_APPLICABLE' : 'PENDING', 
        auto: passwordMethod === 'AUTO' 
      },
    };
  }
  if (currTime > playWindowClosedAt) {
    return {
      status: 'TIMED_OUT',
      playWindowClosedAt,
    };
  }
};

/**
 * Automatically manages the unlock state for all video plays.
 * This function is called periodically to update the unlock state for each video play.
 * It checks the current time, duration, and other factors to determine the appropriate unlock status for each video play.
 * The unlock state is then updated in the Redux store using the `autoUpdateUnlockState` action.
 */
function* autoManage() {
  let state = yield select();
  const allPlays = selectors.allPlays(state);
  let unlockState = {};
  forEach(allPlays, doc => {
    state = store.getState();
    const status = unlockSelectors.status(state, doc._id);
    if (includes(['IN_PROGRESS', 'DONE'], status)) return;

    const computedStatusObj = computeUnlockStatus(state, doc);
    if (!computedStatusObj) return;
    console.debug('autoManage: computedStatus=', computedStatusObj.status, 'status=', status, 'vpsId=', doc._id);
    if (computedStatusObj.status === status) return;
    unlockState[doc._id] = computedStatusObj;
  });

  if (!isEmpty(unlockState)) {
    yield put(actions.autoUpdateUnlockState(unlockState));
  }

  yield delay(5000);
  yield call(autoManage);
}

function* recomputeUnlockState({ playStatusId }) {
  const state = yield select();
  const doc = selectors.doc(state, playStatusId);
  const computedUnlockState = computeUnlockStatus(state, doc);
  const curUnlockState = unlockSelectors.unlockState(state, playStatusId);
  if (isEqual(computedUnlockState, curUnlockState)) {
    return;
  }

  console.debug('recomputeUnlockState: found change. computed=', computedUnlockState, 'curUnlockState=', curUnlockState);
  yield put(actions.autoUpdateUnlockState({ [playStatusId]: computedUnlockState }));
}

function* unlockFlow(playStatusId) {
  yield call(recomputeUnlockState, { playStatusId });
  const state = yield select();
  const status = unlockSelectors.status(state, playStatusId);
  const dialogName = routerSelectors.dialog(state)?.name;
  if (dialogName === 'UNLOCK_VIDEO' || status !== 'READY') {
    console.error('Unlock Dialog is already opened or status is not READY yet.', { dialogName, status });
    return;
  }

  navigateDialog('UNLOCK_VIDEO', { playStatusId });

  const { isGpsApplicable, isRFIdApplicable, isPasscodeApplicable } = unlockSelectors;

  if (isGpsApplicable(state, playStatusId)) {
    const gpsValidated = yield call(validateGPS, { playStatusId });
    if (!gpsValidated) return;
  }

  if (isRFIdApplicable(state, playStatusId)) {
    const rfidValidated = yield call(validateRfid, { playStatusId });
    if (!rfidValidated) return;
  }

  if (isPasscodeApplicable(state, playStatusId)) {
    const success = yield call(validatePasscode, { playStatusId });
    if (!success) {
      yield call(closeUnlockDialog);
      return;
    }

  }

  yield put(actions.unlockSucceed(playStatusId));
}

function* closeUnlockDialog() {
  const state = yield select();
  const dialogName = routerSelectors.dialog(state)?.name;
  if (dialogName !== 'UNLOCK_VIDEO') return;
  back();
}

function* validateGPS({ playStatusId }) {
  const state = yield select();
  const doc = selectors.doc(state, playStatusId);
  const { locations } = doc;
  if (isEmpty(locations)) {
    console.error('validateGPS: No locations found for the video.');
    return false;
  }
  
  yield put(actions.gpsValidationStarted(playStatusId));
  try {
    //validate against last saved location
    const staleLocationDuration = appSelectors.config(state).staleLocationDuration;
    console.debug(`validateGPS: vpsId=${playStatusId}. retrieving last known location.`);
    const lastLocation = yield call(() => window.Gps.lastKnownLocation());
    const timeAllowed = lastLocation && isTimeInAllowedStaleRange(staleLocationDuration, lastLocation.timestamp);
    let validated =
      timeAllowed && isLocationInAnyRange({ lat: lastLocation.coords.latitude, lon: lastLocation.coords.longitude }, doc.locations);
    console.debug(
      'validateGPS: lastLocation=',
      lastLocation,
      'staleLocationDuration=',
      staleLocationDuration,
      'timeAllowed=',
      timeAllowed,
      'validated=',
      validated
    );

    if (!validated) {
      //validate against the current location
      console.debug(`validateGPS: vpsId=${playStatusId}. retrieving current location.`);
      const curLocationResponse = yield call(() => window.Gps.currentLocation(60)); // 60 seconds timeout.
      if (curLocationResponse) {
        validated = isLocationInAnyRange({ lat: coords.latitude, lon: coords.longitude }, doc.locations);
      }
      console.debug('validateGPS: curLocationResponse=', curLocationResponse, 'validated=', validated);
    }

    if (validated) {
      yield put(actions.gpsValidationSucceed(playStatusId));
      return true;
    }

    yield put(
      actions.gpsValidationFailed(playStatusId, {
        code: 'LOCATION_VALIDATION_FAILED',
        message: 'Player is not in the allowed location range.',
      })
    );
    return false;
  } catch (error) {
    console.error('onStartGpsValidation error', error);
    yield put(
      actions.gpsValidationFailed(playStatusId, {
        code: 'GPS_HW_ERROR',
        message: 'GPS Hardware Error',
      })
    );
    return false;
  }
}


/**
 * When RFID validation is started, an corresponding flow is started on video-player-server;
 * This is used to cancel that flow when not required.
 * 
 * It does graceful cancellation; and invoking it multiple times will not cause any issue.
 */
async function _cancelRfidValidation() {
  try {
    // console.debug('_cancelRfidValidation:', rfidHandle);
    rfidReject && rfidReject("Cancelled");
    rfidHandle && await rfidHandle.cancel();
    console.debug('_cancelRfidValidation: done');
  } catch (e) {
    console.error('_cancelRfidValidation: failed', e);
  } finally {
    rfidReject = null;
    rfidHandle = null;
  }
}

/**
 * When rfid validation is in progress, these values will be set.
 */
let rfidHandle, rfidReject;


/**
 * Performs RFID  validation.
 * 
 * On successful validation promise is resolved. On failure, promise is rejected with error.
 * On intermediate invalid tag, `RFID_VALIDATION_INVALID_TAG` action is dispatched, so it ca be reflected on redux state and UI.
 * 
 * @param {*} playStatusId - VPS Id
 * @param {*} rfid - Expected value of RFID Tag
 * @returns 
 */
function _validateRfid(playStatusId, rfid) {
  return new Promise(async (resolve, reject) => {
    rfidReject = reject;
    rfidHandle = await window.Rfid.captureStart((tagResponse) => {
      if (tagResponse.success) {
          // const tagId = tagResponse.data.id;
          const tagData = tagResponse.data.data;
          if (rfid === tagData) {
            console.info(`validateRfid: done. vpsId=${playStatusId}`);
            rfidReject = null;
            resolve();
          } else {
            console.info(`validateRfid: invalid tag. vpsId=${playStatusId}, presented rfid tag=${tagData}`);
            store.dispatch(actions.rfidValidationInvalidTag(playStatusId));
          }
      } else {
        console.error(`validateRfid: failed. vpsId=${playStatusId}, response=`, tagResponse);
        rfidReject = null;
        reject({
          code: 'RFID_HW_ERROR',
          message: 'RFID Hardware Error'
        });
      }
    });
  });
}


/**
 * Main Saga to perform RFID validation for a VPS.
 * It should not be invoked if rfId is not applicable for the video.
 * @returns 
 */
function* validateRfid({ playStatusId }) {
  const state = yield select();
  const doc = selectors.doc(state, playStatusId);
  const { rfid } = doc;

  if (!rfid) {
    console.error('onStartRfidValidation: No RFID found for the video.');
    return false;
  }

  // console.debug('validateRfid: started. vpsId=', playStatusId);
  yield put(actions.rfidValidationStarted(playStatusId));
  try {
    yield call(_validateRfid, playStatusId, rfid);
    yield put(actions.rfidValidationSucceed(playStatusId));
    return true;
  } catch (error) {
    if (error === 'CANCELLED') {
      return;
    }
    console.error(`validateRfid: failed. vpsId=${playStatusId}`, error);
    yield put(actions.rfidValidationFailed(playStatusId, error));
    return false;
  } finally {
    _cancelRfidValidation();
  }
}

function* incrementUsedPasscodeAttempts(vpsId) {
  const state = yield select();
  const doc = selectors.doc(state, vpsId);
  const usedPasscodeAttempts = doc.usedPasscodeAttempts || 0;
  doc.usedPasscodeAttempts = usedPasscodeAttempts + 1;

  yield call(saveDoc, doc); 
  return doc;
}

function* resetUsedPasscodeAttempts(vpsId) {
  const state = yield select();
  const doc = selectors.doc(state, vpsId);
  if (!doc.usedPasscodeAttempts) return doc;
  
  doc.usedPasscodeAttempts = 0;

  //on unlock, when video play is auto-started, VPS document.status is also updated;
  //Given that, if doc is updated in the background; then it will cause conflict.
  //So, don't update the doc in the background.
  yield call(saveDoc, doc);
  return doc;
}

async function saveDoc(doc) {
  const response = db.save(doc);
  await response.localWrite;
  setTimeout(() => window.ChromeManager && window.ChromeManager.flushChromeCache(), 1000);
}

function* validatePasscode({ playStatusId }) {
  yield put(actions.passcodeValidationStarted(playStatusId));
  const state = yield select();
  const doc = selectors.doc(state, playStatusId);
  const { passwordMethod } = doc;

  if (!passwordMethod) {
    throw new Error('Password method is not found.');
  }

  while (true) {
    const taskGetPassword =
      passwordMethod === 'ASK' ? take(actions.VERIFY_PASSCODE) : call(getPasscode, { scheduleId: doc.scheduleId, kendraId: doc.kendraId });
    const { passcode } = yield taskGetPassword;
    const passcodeValidated = yield call(openPlaySession, { playStatusId, passcode });
    if (passcodeValidated) {
      yield put(actions.passcodeVerified(playStatusId));
      yield call(resetUsedPasscodeAttempts, playStatusId);
      return true;
    }

    const vps = yield call(incrementUsedPasscodeAttempts, playStatusId);
    const lockedOut = vps.maxPasscodeAttempts && (vps.usedPasscodeAttempts >= vps.maxPasscodeAttempts);
    yield put(actions.invalidPasscode(playStatusId, lockedOut));

    if (lockedOut) {
      return false;
    }

    if (passwordMethod === 'AUTO') {
      yield delay(3000); //retry the API after 3 seconds.
    }
  }
}

function* getPasscode({ scheduleId, kendraId }) {
  try {
    // console.debug('getPasscode: started', scheduleId, kendraId);
    const state = yield select();
    const username = registrationSelector.playerId(state);
    const password = registrationSelector.authToken(state);
    const passcode = yield call(() => api(`/schedule-password/${scheduleId}/${kendraId}`, { 
      method: 'GET',
      headers: {
        'Authorization': `Basic ${btoa(`${username}:${password}`)}`
      }
    }));
    // console.debug('getPasscode: passcode=', passcode);
    return {passcode};
  } catch (e) {
    console.error('getPasscode: failed', e);
    return '';
  }
}

/**
 * Starts play session.
 * 
 * @param {*} param0
 *  @property {String} playStatusId Play status Id.
 *  @property {String} passcode Passcode
 * @returns `true` if play session is started successfully, `false` otherwise.
 */
function* openPlaySession({ playStatusId, passcode }) {
  console.debug('openPlaySession : started', playStatusId, passcode);
  const state = yield select();
  const playStatusDoc = selectors.doc(state, playStatusId);
  const videoId = playStatusDoc?.videoId;
  const videoDoc = selectors.doc(state, videoId);
  if (videoDoc._deleted) {
    console.debug('openPlaySession : video is deleted', playStatusId);
    throw new Error('Video is deleted');
  }

  if (!playStatusDoc.playKey) {
    throw new Error('Video playKey is not found');
  }

  try {
    yield call(() => window.VideoManager.playOpen(videoId, playStatusDoc.playKey, passcode));
    console.info('openPlaySession: passcode validated', playStatusId);

    if (playStatusDoc.prefixVideoId) {
      yield call(() => window.VideoManager.playOpen(playStatusDoc.prefixVideoId, playStatusDoc.prefixKey, null));
      console.info('openPlaySession: prefixKey validated', playStatusId);
    }

    if (playStatusDoc.postfixVideoId) {
      yield call(() => window.VideoManager.playOpen(playStatusDoc.postfixVideoId, playStatusDoc.postfixKey, null));
      console.info('openPlaySession: postfixKey validated', playStatusId);
    }

    return true;
  } catch (error) {
    console.info(`openPlaySession: invalid passcode. passcode=${passcode}, playStatusId=${playStatusId}`);
    return false;
  }
}

function* onFetchPasscode({ playStatusId }) {
  const state = yield select();
  const doc = selectors.doc(state, playStatusId);
  try {
    // TODO: Fetch passcode from server.
    yield put(actions.fetchPasscodeSucceed(playStatusId, passcode));
  } catch (error) {
    yield put(actions.fetchPasscodeFailed(playStatusId, error));
  }
}


/**
 * If any Video is playable it will start playing.
 * Cases:
 * 1. Unlock is done after the play-time starts
 * 2. Video was stopped due to power failure, on next start and on unlock video will auto-resume from where it was stopped.
 * @param {*} param0 
 * @returns 
 */
function* onUnlockSucceed({ playStatusId }) {
  try {
    const state = yield select();
    const isPlayable = selectors.isPlayable(state, playStatusId);
    if (!isPlayable) return;

    yield put(actions.startPlay(playStatusId));
  } catch (error) {
    console.error('onUnlockSucceed: failed.', error);
  }
}

/**
 * Init Saga.
 */
export default function* saga() {
  const state = yield select();
  const dbName = appSelectors.dbName(state);
  db = dreamdb.couchdb().db(dbName);

  yield fork(autoManage);

  while (true) {
    const { playStatusId } = yield take(actions.UNLOCK_VIDEO);
    let task = yield fork(unlockFlow, playStatusId);
    const { type } = yield take([actions.CANCEL_UNLOCK, actions.UNLOCK_SUCCEED]);
    yield call(closeUnlockDialog);
    yield cancel(task);

    if (type === actions.UNLOCK_SUCCEED) {
      yield call(onUnlockSucceed, { playStatusId });
    } else {
      const computedState = yield call(recomputeUnlockState, { playStatusId });
      yield put(actions.autoUpdateUnlockState(computedState));
    }
  }
}
