const EARTH_RADIUS = 6371e3; // in meters

const toRadians = degrees => degrees * (Math.PI / 180);

const calculateDistance = (loc1, loc2) => {
  const lat1 = toRadians(loc1.lat);
  const lat2 = toRadians(loc2.lat);
  const deltaLat = toRadians(loc2.lat - loc1.lat);
  const deltaLong = toRadians(loc2.lon - loc1.lon);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLong / 2) * Math.sin(deltaLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return EARTH_RADIUS * c;
};

/**
 * Returns `true` if the given location is within the radius of the given center.
 * @param {*} location - {lat: 0, lon: 0}
 * @param {*} center  - {lat: 0, lon: 0, radius: 0}
 * @returns
 */
export function isLocationInRange(location, center) {
  const distance = calculateDistance(location, center);
  console.debug('isLocationInRange: location=', location, 'center=', center, 'distance=', distance);
  return distance <= center.radius;
}

/**
 * Returns `true` if the given location is within the radius of any of the given centers.
 * @param {*} location {lat: 0, lon: 0}
 * @param {*} centers Array of allowed location+radius `[{lat: 0, lon: 0, radius: 10 //in meters}]`
 */
export function isLocationInAnyRange(location, centers) {
  for (const center of centers) {
    const inRadius = isLocationInRange(location, center);
    if (inRadius) {
      return true;
    }
  }
  return false;
}


export function isTimeInAllowedStaleRange(staleLocationDuration, timestamp) {
  const staleLocationTime = staleLocationDuration * 60 * 1000;
  const curTime = Date.now();
  return timestamp > (curTime - staleLocationTime)  && timestamp <= Date.now();
}