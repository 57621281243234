import { find, forEach } from 'lodash-es';
import { createSelector } from 'reselect';
import * as constants from './constants';

/**
 * Retrieves the status of the video unlock for the given state and video ID.
 *
 * @param {Object} state - The Redux state.
 * @param {string} playStatusId - The video play stauts id
 * @returns {string|undefined} The status of the video unlock, or undefined if not found.
 */
export const status = (state, playStatusId) => state?.videoUnlock?.[playStatusId]?.status;

export const unlockState = (state, playStatusId) => state?.videoUnlock?.[playStatusId];

/**
 * @param {Object} state - The Redux state.
 * @param {string} playStatusId - The video play status id.
 * @returns {Array|undefined} An array of applicable unlock steps, containing the GPS, RFID, and passcode info for video unlock dialog.
 */
export const applicableSteps = createSelector(
  (state, playStatusId) => state?.videoUnlock?.[playStatusId],
  currState => {
    if (!currState) return;
    let steps = [];
    forEach(constants.fields, name => {
      const value = currState?.[name];
      if (!value?.status || value.status === 'NOT_APPLICABLE') return;

      steps.push({ ...value, name });
    });
    return steps;
  }
);

/**
 * @param {Object} state - The Redux state.
 * @param {string} playStatusId - The video play status ID.
 * @returns {Object|undefined} The current unlock step, or undefined if no step is currently pending or in progress.
 */
export const currentStep = createSelector(
  (state, playStatusId) => applicableSteps(state, playStatusId),
  steps => {
    return find(steps, step => {
      const { status } = step;
      return status === 'PENDING' || status === 'IN_PROGRESS' || status === 'FAILED';
    });
  }
);

const isFieldApplicable = (state, playStatusId, fieldName) => {
  const status = state?.videoUnlock?.[playStatusId]?.[fieldName]?.status;
  return status && status !== 'NOT_APPLICABLE';
};

export const isGpsApplicable = (state, playStatusId) => {
  return isFieldApplicable(state, playStatusId, 'gps');
};

export const isRFIdApplicable = (state, playStatusId) => {
  return isFieldApplicable(state, playStatusId, 'rfid');
};

export const isPasscodeApplicable = (state, playStatusId) => {
  return isFieldApplicable(state, playStatusId, 'passcode');
};
