import dreamdb from '@dw/dreamdb-client';
import { all, call, select, take } from 'redux-saga/effects';
import * as appActions from '../app/actions.js';
import * as appSelectors from '../app/selectors';
import sagaDownload from './saga-download.js';
import sagaPlay from './saga-play.js';
import sagaUnlock from './saga-unlock.js';


let queryRef;

/**
 * Starts query of videos, it's download status & it's plays.
 * Completes when initial value is retrieved.
 */
function* getData() {
  const state = yield select();
  const dbName = appSelectors.dbName(state);
  try {
    const db = dreamdb.couchdb().db(dbName);
    queryRef = db.find({
      queryId: "videos",
      selector: { type: { $in: ['Video', 'VideoDownloadStatus', 'VideoPlayStatus'] } },
      useIndex: '_design/index-by-type',
      limit: 99999
    });

    //wait till localQuery result is not available
    yield call(() => {
      if (queryRef.metadata.localInSync) {
        return;
      }
      let resolve, reject;
      const promise = new Promise((res, rej) => {resolve = res, reject = rej});

      queryRef.on('metadata', (metadata) => {
        if (metadata.localInSync) {
          resolve();
          return;
        }

        if (metadata.error) {
          reject(metadata.error);
        }
      });
      return promise;
    });

  } catch (error) {
    console.error('Failed to retrieve data from dreamdb.', error);
    //ignore error here, it would be handled by 'watchQueryUnauthorized'.
  }
}

function* waitTillDreamdbInit() {
  const state = yield select();
  if (appSelectors.dreamdbInitDone(state)) {
    return;
  }
  yield take(appActions.DREAMDB_INIT_DONE);
}

/**
 * Init Saga.
 */
export default function* saga() {
  yield call(waitTillDreamdbInit);
  console.info('videos: saga. dreamdb init done');
  yield call(getData);
  console.info('videos: getData: done');
  yield all([call(sagaUnlock), call(sagaPlay), call(sagaDownload)]);
}
