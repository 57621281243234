import get from 'lodash-es/get';
import * as registration from '../registration';

export const currentPage = state => get(state, `router.page`, {});

/**
 * @param {Object} state Redux state
 * @returns {String} Current page name. When registration status is not `ACTIVE`, returns `REGISTRATION` othwerwise router page name.
 */
export const currentPageName = state => {
  const routerPage = get(state, `router.page.name`);
  const registrationStatus = registration.selectors.status(state);

  if(registrationStatus !== "ACTIVE" && routerPage !== "VIDEO_CONTROLLER") {
    return "REGISTRATION";
  }

  return routerPage;
};

/**
 * @param {Object} state Redux state.
 * @returns {Object} Current opened dialog detail.
 */
export const dialog = (state) => get(state, `router.dialog`, {});
