import { put, takeEvery, call, select, all, take, actionChannel, spawn, delay } from 'redux-saga/effects';
import { store } from '../store';
import * as actions from './actions';
import * as selectors from './selectors';
import * as appSelectors from '../app/selectors.js';
import * as registration from '../registration';
import dreamdb from '@dw/dreamdb-client';
import isEqual from 'lodash-es/isEqual';


const DREAMDB_DOCS_UPDATED = 'DREAMDB_DOCS_UPDATED';
const DREAMDB_QUERY_SNAPSHOT = 'DREAMDB_QUERY_SNAPSHOT';

let db; // Database instance of the DreamDB.

let dm; // Download manager.
let dmInitialized = false;
let dmAuthHeaders = {};

/**
 * Initializes `downloadManager`.
 * After initialization, gets data for curent devices.
 */
function* initializeDownloadManager() {
  if (dmInitialized) {
    return;
  }

  const state = yield select();
  const playerId = registration.selectors.playerId(state);
  const authToken = registration.selectors.authToken(state);
  if (!authToken) {
    console.info('initializeDownloadManager: authToken not found, so skipped.');
    return;
  }

  const authHeader = `Basic ${btoa(playerId + ':' + authToken)}`;
  if (window.VideoManager) {
    dmAuthHeaders = { Authorization: authHeader };
    console.info('initializeDoanloadManager: done');
  } else {
    console.warn('Download Manager does not exists yet.');
  }
  dmInitialized = true;
}

let downloadQueueInProgress = false;

function* startDownloadQueue() {
  if (downloadQueueInProgress) {
    console.debug('startDownloadQueue: already running.');
    return;
  }
  downloadQueueInProgress = true;

  yield call(initializeDownloadManager);

  console.debug('startDownloadQueue: started.');
  let state, vds;
  while (true) {
    state = yield select();
    vds = selectors.nextPending(state);
    if (!vds) {
      console.debug('startDownloadQueue: completed.');
      downloadQueueInProgress = false;
      return;
    }
    console.info(`downloadQueu: going to start`, vds, state);
    yield call(download, vds);
  }
}

function* download(vds) {
  let video;
  while (true) {
    //Sometimes it happens that Video is received on the player with slight delay. 
    //This loop confirms that Video is also received on the Player
    const state = yield select();
    video = selectors.videoDetail({state, videoId: toVideoId(vds._id)});
    if (video._id) {
      break;
    }
    console.debug('download, video not found in state, will be retried in 1 second', vds._id, state);
    yield delay(1000);
  }
  try {
    yield call(setVdsStatusToInProgress, video._id);
    
    let downloadCompleteResolve, downloadCompleteReject;
    const downloadComplete = new Promise((resolve, reject) => {
      downloadCompleteResolve = resolve;
      downloadCompleteReject = reject;
    });

    yield call(() => VideoManager.download({
      url: video.downloadUrl,
      videoId: video._id,
      checksum: video.checksum,
      headers: dmAuthHeaders,
      onStatus: ({videoId, status, progress}) => {
        //possible values of 'status' are: DOWNLOADING, VALIDATING_CHECKSUM, CHECKSUM_FAILED, EXTRACTING, DONE, ERROR, CANCELLED, SERVER_ERROR
        //const {total, downloaded, speed} = progress; //This is available only when status is DOWNLOADING.
        switch (status) {
          case 'DOWNLOADING':
            store.dispatch(actions.downloadProgress(videoId, progress));
            break;
          case 'VALIDATING_CHECKSUM':
            store.dispatch(actions.validationStarted(videoId));
            break;
          case 'DONE':
            downloadCompleteResolve();
            break;
          case 'CANCELLED':
            downloadCompleteReject("Cancelled");
            break;
          case 'ERROR':
            downloadCompleteReject("Download error on video-player-server.");
            break;
          case 'CHECKSUM_FAILED':
            downloadCompleteReject("Checksum validation failed.");
            break;
          case 'SERVER_ERROR':
            downloadCompleteReject("Server error.");
            break;
        }
      }
    }));

    const wait = () => downloadComplete;

    yield call(wait);
    yield call(setVdsStatusToCompleted, video._id);
  } catch (e) {
    yield call(handleDownloadError, vds, video, e);
  }
}

function* onDownloadProgress(action) {
  console.debug('onDownloadProgress', action);
  yield call(updateVdsProgress, action.videoId, action.stats);
}

function* onValidationStarted(action) {
  console.debug('onValidationStarted', action);
  yield call(setVdsStatusToValidating, action.videoId);
}


function* setVdsStatusToPending(videoId, retryCount) {
  yield call(updateVdsDoc, videoId, {
    status: "PENDING",
    retryCount: retryCount
  });
}

function* setVdsStatusToInProgress(videoId) {
  yield call(updateVdsDoc, videoId, {
    status: "IN_PROGRESS",
    startedAt: new Date().getTime(),
    downloadedBytes: 0,
    duration: 0,
    estimatedRemainingTime: 0,
    speed: 0
  });
}

/**
 * Records timestamp when last VideoDownloadStatus was written to dreamdb.
 * As dreamdb write operation need to throttle at 3 seconds interval, this 
 * is used for the computation.
 */
let lastProgressWritten = 0;

function* updateVdsProgress(videoId, stats) {
  const state = yield select();
  const doc = selectors.videoDownloadStatus({ state, videoId });

  const speed = stats.speed;
  const downloadedBytes = stats.downloaded;
  const remainingBytes = stats.total - downloadedBytes;
  const duration = (doc.duration || 0) + 1;
  const estimatedRemainingTime = !speed ? 0 : Math.ceil(remainingBytes / speed);
  const changes = {speed, downloadedBytes, duration, estimatedRemainingTime};

  const skipDb = (new Date().getTime() - lastProgressWritten) <= 3000;
  yield call(updateVdsDoc, videoId, changes, skipDb);
  if (!skipDb) {
    lastProgressWritten = new Date().getTime();
  }
}

function* setVdsStatusToValidating(videoId) {
  const state = yield select();
  const doc = selectors.videoDownloadStatus({ state, videoId });
  if (doc.status !== 'IN_PROGRESS') {
    console.info("skipped changing status to VALIDATING, as current status is not IN_PROGRESS", doc);
    return;
  }

  yield call(updateVdsDoc, videoId, {
    status: "VALIDATING",
    estimatedRemainingTime: 0,
    speed: 0
  });
}

function* setVdsStatusToCompleted(videoId) {
  yield call(updateVdsDoc, videoId, {
    status: "COMPLETED",
    completedAt: new Date().getTime()
  });
}

function* setVdsStatusToError(videoId, error) {
  yield call(updateVdsDoc, videoId, {
    status: "ERROR",
    error: error
  });
}

function* updateVdsDoc(videoId, changes, skipDb, retried) {
  const state = yield select();
  const doc = selectors.videoDownloadStatus({ state, videoId });
  // console.log('updateVdsDoc', videoId, doc);
  if (doc._deleted) {
    console.debug(`updateVdsDoc: skipped as deleted. videoId=${videoId}`);
    return;
  }
  
  const newDoc = { ...doc, ...changes, lastUpdatedAt: new Date().getTime() };
  //console.info('updateVdsDoc', newDoc._id, newDoc.status, newDoc, changes, skipDb);

  yield put(actions.updateDownloadStatus(videoId, newDoc));

  if (!skipDb && !isEqual(doc, newDoc)) {
    try {
      const req = yield call(db.save.bind(db), newDoc);
      yield call(() => req.localWrite);
    } catch (e) {
      if (e.name == 'conflict') {
        if (retried) {
          console.warn('updateVdsDoc: doc conflict, retry failed; no more retry now.', newDoc, e, retried);
        } else {
          console.debug('updateVdsDoc: doc conflict, going to retry', newDoc, e);
          yield call(updateVdsDoc, videoId, changes, skipDb, true);
        }
      } else {
        console.error('updateVdsDoc: failed.', newDoc, e);
      }
    }
  }
}

function* handleDownloadError(vds, video, e) {
  if (e === 'cancelled') {
    console.info('download cancelled.', vds._id);
    return;
  }
  
  if (typeof e === 'string' && e.indexOf('client-error') === 0) {
    console.info(`download failed due to ${e}. Vds would be deleted`);
    yield call(updateVdsDoc, video._id, {_deleted: true});
    return;
  }
  
  if (vds.retryCount === 2) {
    console.error('handleDownloadError: retry exhausted', e);
    const errorMessage = typeof e === 'string' ? e : e.message;
    yield call(setVdsStatusToError, video._id, errorMessage);
    return;
  }

  const retryCount = vds.retryCount ? ++vds.retryCount : 1;
  console.warn(`handleDownloadError: going to retry. retryCount=${retryCount}`, e);
  yield call(setVdsStatusToPending, video._id, retryCount);
  yield call(() => VideoManager.delete(video._id));
}

/**
 * For all VDS in UNKNOWN status, changes status to PENDING; and starts download queue.
 */
function* handleNewVideos(skipDownloadQueue) {
  const state = yield select();
  const unknownVds = selectors.allUnknownVds(state);
  console.debug(`handleNewVideos, unknownVds`, unknownVds);
  for (const vds of unknownVds) {
    console.info(`handleNewVideos: new vds found:`, vds._id);
    yield call(setVdsStatusToPending, toVideoId(vds._id));
  }
  if (!skipDownloadQueue && unknownVds.length > 0) {
    yield spawn(startDownloadQueue);
  }
  console.debug('handleNewVideos: completed');
}


function* sequantialHandleNewVideos() {
  const docsChannel = yield actionChannel([DREAMDB_DOCS_UPDATED, DREAMDB_QUERY_SNAPSHOT]);
  while (true) {
    console.debug('sequantialHandleNewVideos: waiting for action');
    yield take(docsChannel);
    yield call(handleNewVideos);
  }
}

/**
 * Deletes video files from the disk, whose document have been deleted.
 */
function* handleVideoDeletion() {
  const state = yield select();
  const pendingDeletionVds = selectors.pendingForDeletion(state);
  console.debug(`handleVideoDeletion, pendingDeletionVds`, pendingDeletionVds);
  for(const vds of pendingDeletionVds) {
    const videoId = toVideoId(vds._id);
    if (vds.status === 'IN_PROGRESS') {
      console.info(`handleVideoDeletion: stop downloading ${videoId}`);
      yield call(() => VideoManager.cancel(videoId));
    }
    yield put(actions.videoDeleted(videoId));
    console.info(`handleVideoDeletion: delete video file ${videoId}`);
    yield call(() => VideoManager.delete(videoId));
  }
}

const toVideoId = (vdsId) => vdsId.replace('vds_', 'vd_');


/**
 * On DREAMDB_DOCS_UPDATED, invokes handleVideoDeletion sequntially. So, another call
 * to `handleVideoDeletion` is deferred until first call is completed.
 */
function* sequantialVideoDeletion() {
  const docsChannel = yield actionChannel([DREAMDB_DOCS_UPDATED, DREAMDB_QUERY_SNAPSHOT]);
  while (true) {
    yield take(docsChannel);
    yield call(handleVideoDeletion);
  }
}


/**
 * Init Saga.
 */
export default function* saga() {
  const state = yield select();
  const dbName = appSelectors.dbName(state);
  db = dreamdb.couchdb().db(dbName);
  
  yield call(initializeDownloadManager);
  yield takeEvery(actions.DOWNLOAD_PROGRESS, onDownloadProgress);
  yield takeEvery(actions.VALIDATION_STARTED, onValidationStarted);
  yield call(handleVideoDeletion);
  yield call(handleNewVideos, true);
  yield all([
    call(startDownloadQueue),
    call(sequantialHandleNewVideos),
    call(sequantialVideoDeletion),
  ]);
}