import fetch from '@dreamworld/fetch';
import * as app from '../redux/app';
import {store} from '../redux/store.js';

const defaultOptions = {
  mode: 'cors',
  // credentials: 'include',
  headers: {
    "Content-Type": "application/json"
  }
};

/**
 *
 * @param {String} url - API Url.
 * @param {Object} options - Request data
 *  - `mode` - By default its value is `cors`
 *  - `credentials` - By default is value is `include`.
 *  - `headers` - By default it sets `Content-Type": "application/json`
 *                Sends `Authorization` header if user is logged-in and accessToken is available.
 *  - `body` - Set request body as stringify json. If it is JSON then it strigify the request body data.
 *  - `excludeErrors`: Array of HTTP status code which should not logged as error when API is failed with this status code.
 * @return Promise -
 *  - Resolved only when API response is OK with response body data only, otherwise reject promise with state and error code and message
 *
 * NOTE: If Any API is failed with `401` status code then it fires `api-auth-failed` event on window.
 * Note: Fetch method documentation:  https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 */
export default async (url, options = {}) => {
  const {apiBaseUrl} = app.selectors.config(store.getState());
  if (url && url.startsWith('/')) {
    url = apiBaseUrl + url;
  }

  options = { ...defaultOptions, ...options };

  if (options.body && typeof options.body !== "string" && !(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
  }

  if (options.body instanceof FormData) {
    if (options.headers && options.headers['Content-Type']) {
      delete options.headers['Content-Type'];
    }
  }

  let aExcludeErrors = (options.excludeErrors && Array.isArray(options.excludeErrors)) ? [...options.excludeErrors] : [];
  delete options.excludeErrors;

  let res;
  try {
    res = await fetch(url, options);
  } catch (err) {

    //When network error OR request is blocked by the browser (e.g. CORS config of the server isn't ok)
    throw { status: 0 };
  }

  if (res.status && res.status === 401 && aExcludeErrors.indexOf(401) == -1) {
    // Dispatch 'api-auth-failed' event on window when API is failed due to unauthorized access
    window.dispatchEvent(new CustomEvent('api-auth-failed', {}));

    //return a Promise which is never resolved.
    return new Promise(() => { });
  }

  let responseText;
  try {
    responseText = await res.text();
    responseText = responseText.trim();
  } catch (e) {
    console.error("Failed to retrieve responseText", e);
  }

  let responseJSON;
  if (res.headers.get('Content-Type') && res.headers.get('Content-Type').includes('application/json')) {
    try {
      responseJSON = JSON.parse(responseText);
    } catch (e) {
      //ignore
    }
  }

  if (res.ok) {
    return getResponseObject(responseJSON, responseText, res.status);
  }

  if (aExcludeErrors.indexOf(res.status) == -1) {
    console.error(`API failed. url: ${url}, statuscode: ${res.status}, body: ${responseText}`);
  }

  return getResponseObject(responseJSON, responseText, res.status);
}

let getResponseObject = (json, text, status) => {
  let error = status && status >= 400;
  if (!error) {
    if (json) {
      return json;
    }

    return text;
  }


  if (json) {
    throw { ...json, status };
  }

  throw {
    status: status,
    code: 'JSON_PARSE_FAILED',
    error: 'Failed to parse resposne as Json.',
    text: text
  };
}
